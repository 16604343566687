import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import 'styles/main.scss';
import MobileLogsPage from 'pages/MobileLogsPage';
import ServerMainApiLogsPage from 'pages/ServerMainApiLogsPage';
import ServerMessagingApiLogsPage from 'pages/ServerMessagingApiLogsPage';
import HeaderNav from 'components/HeaderNav';

function App() {
  return (
    <Router>
      <HeaderNav />
      <Routes>
        <Route exact path="/" element={<MobileLogsPage />} />
        <Route
          path="/server-main-api-logs"
          element={<ServerMainApiLogsPage />}
        />
        <Route
          path="/server-messaging-api-logs"
          element={<ServerMessagingApiLogsPage />}
        />
      </Routes>
    </Router>
  );
}

export default App;
