import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';

function HeaderNav() {
  return (
    <Navbar fixed="top" className="header-nav">
      <Nav className="me-auto" variant="tabs">
        <LinkContainer to="/">
          <Nav.Link>Mobile Application</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/server-main-api-logs">
          <Nav.Link>Server Main API</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/server-messaging-api-logs">
          <Nav.Link>Server Messaging API</Nav.Link>
        </LinkContainer>
      </Nav>
    </Navbar>
  );
}

export default HeaderNav;
