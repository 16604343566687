import React from 'react';
import ReactJson from 'react-json-view';

import { ApiType } from 'config';

function LogDetailModal({ logDetailed }) {
  return (
    <>
      {logDetailed?.exceptionResponse && (
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          name="Exception Response"
          src={JSON.parse(logDetailed?.exceptionResponse)}
        />
      )}
      {logDetailed?.params && (
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          name="Params"
          src={JSON.parse(logDetailed?.params)}
        />
      )}
      {logDetailed?.requestBody && (
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          name="Body"
          src={JSON.parse(logDetailed?.requestBody)}
        />
      )}
      {logDetailed?.user && (
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          name="User"
          src={JSON.parse(logDetailed?.user)}
        />
      )}
      {logDetailed?.api === ApiType.Messaging && (
        <ReactJson
          displayDataTypes={false}
          displayObjectSize={false}
          name="Messaging"
          src={logDetailed}
        />
      )}
    </>
  );
}

export default LogDetailModal;
