import { forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import { Apple, InfoSquare } from 'react-bootstrap-icons';

import formatDate from 'tools/formatDate';
import { ReactComponent as Android } from 'images/android.svg';
import { LogType } from 'config';

const LogTable = forwardRef(
  (
    {
      stickyOffset = 0,
      showEnvironment = true,
      showOpenSession = true,
      hiddenFields = [],
      collection,
      onSessionOpen,
    },
    ref,
  ) => {
    return (
      <table className="log-table" ref={ref}>
        <thead>
          <tr
            className="log-table__header"
            style={stickyOffset ? { top: stickyOffset + 'px' } : undefined}>
            {hiddenFields.includes('version') ? null : (
              <th width="1" className="log-table__cell">
                Ver
              </th>
            )}
            {hiddenFields.includes('operatingSystem') ? null : (
              <th width="1" className="log-table__cell">
                OS
              </th>
            )}
            {hiddenFields.includes('environment') ? null : (
              <th width="1" className="log-table__cell">
                Env
              </th>
            )}
            <th className="log-table__cell">Content</th>
            <th className="log-table__cell log-table__cell--email">
              User Email
            </th>
            <th className="log-table__cell">Date</th>
            {hiddenFields.includes('appStartTime') ? null : (
              <th width="1" className="log-table__cell" />
            )}
          </tr>
        </thead>
        <tbody>
          {[...collection].reverse().map((item) => {
            let rowClassName = 'log-table__row';

            if (item.type === LogType.Error) {
              rowClassName += ' log-table__row--error';
            }

            let osIcon;

            if (item.operatingSystem === 'ios') {
              osIcon = <Apple size={20} color="grey" />;
            } else if (item.operatingSystem === 'android') {
              osIcon = <Android />;
            }

            const contentCellClass =
              'log-table__cell log-table__cell--content' +
              (item.content.length > 240 ? ' log-table--long-text' : '');

            return (
              <tr className={rowClassName} key={item._id}>
                {hiddenFields.includes('version') ? null : (
                  <td className="log-table__cell">{item.version}</td>
                )}
                {hiddenFields.includes('operatingSystem') ? null : (
                  <td className="log-table__cell">{osIcon}</td>
                )}
                {hiddenFields.includes('environment') ? null : (
                  <td className="log-table__cell">{item.environment}</td>
                )}
                <td className={contentCellClass}>{item.content}</td>
                <td
                  className="log-table__cell log-table__cell--email"
                  title={item.userEmail}>
                  {item.userEmail}
                </td>
                <td className="log-table__cell log-table__cell--date">
                  {formatDate(item.createdAt)}
                </td>
                {hiddenFields.includes('appStartTime') ? null : (
                  <td className="log-table__cell">
                    <Button
                      size="sm"
                      variant="link"
                      className="log-table__open-session-link"
                      title="See App Session Details"
                      onClick={() => {
                        if (onSessionOpen) {
                          onSessionOpen(item.appStartTime);
                        }
                      }}>
                      <InfoSquare size={18} />
                    </Button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  },
);

export default LogTable;
