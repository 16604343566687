import { useEffect, useState, useCallback, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Apple } from 'react-bootstrap-icons';
import isEmpty from 'lodash/isEmpty';

import { fetchLogs } from 'tools/api';
import LogTable from 'components/mobile/LogTable';
import { ReactComponent as Android } from 'images/android.svg';
import { LogSource } from 'config';

function AppSessionContent({ sessionId }) {
  const [collection, setCollection] = useState([]);
  const [totalCount, setTotalCount] = useState(+Infinity);

  const fetchInitial = useCallback(() => {
    fetchLogs({ appStartTime: sessionId }, LogSource.Mobile).then((result) => {
      setTotalCount(result.totalCount);
      setCollection(result.collection);
    });
  }, [sessionId]);

  useEffect(() => {
    fetchInitial();
  }, [fetchInitial]);

  const deviceInfo = useMemo(() => {
    let result = null;

    if (!isEmpty(collection)) {
      const { deviceInfo } =
        collection.findLast((item) => !isEmpty(item.deviceInfo)) || {};

      if (deviceInfo) {
        try {
          const info = JSON.parse(deviceInfo);

          if (Object.keys(info).length > 4) {
            const emulator = info.isDevice ? '' : ' (Emulator)';
            const parts = [`${info.brand} ${info.modelName}${emulator}`];
            let osName = info.osName;

            if (osName?.length > 24) {
              //some manufacturer put there unreadable long data
              if (collection[0].operatingSystem === 'ios') {
                osName = 'iOS';
              } else if (collection[0].operatingSystem === 'android') {
                osName = 'Android';
              }
            }

            parts.push(Math.round(info.totalMemory / 1073741824) + 'GB');
            parts.push(`${osName} ${info.osVersion}`);
            result = parts.join(' \u2022 ');
          }
        } catch (error) {}
      }
    }

    return result;
  }, [collection]);

  return (
    <>
      <Modal.Header closeButton>
        {collection.length > 0 ? (
          <div>
            <span
              className={`app-session-content__${collection[0].operatingSystem}-icon`}>
              {collection[0].operatingSystem === 'ios' && (
                <Apple size={20} color="grey" />
              )}
              {collection[0].operatingSystem === 'android' && (
                <Android width={22} />
              )}
            </span>
            <span className="app-session-content__version">
              <strong>App Version: </strong>
              {collection[0].version}
            </span>
            <span className="app-session-content__environment">
              <strong>Environment: </strong>
              {collection[0].environment}
            </span>
            {deviceInfo ? (
              <span className="app-session-content__device">
                <strong>Device: </strong>
                {deviceInfo}
              </span>
            ) : null}
          </div>
        ) : null}
      </Modal.Header>
      <Modal.Body>
        {totalCount === 0 ? (
          <div className="empty-result">No Results</div>
        ) : (
          <LogTable
            className="boo"
            hiddenFields={[
              'version',
              'operatingSystem',
              'environment',
              'appStartTime',
            ]}
            collection={collection}
          />
        )}
      </Modal.Body>
    </>
  );
}

export default AppSessionContent;
