import React, { forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import { InfoSquare } from 'react-bootstrap-icons';

import formatDate from 'tools/formatDate';

const MessagingApiLogTable = forwardRef(
  ({ stickyOffset = 0, collection, onShowLogDetailed }, ref) => {
    return (
      <table className="log-table" ref={ref}>
        <thead>
          <tr
            className="log-table__header"
            style={stickyOffset ? { top: stickyOffset + 'px' } : undefined}>
            <th className="log-table__cell">Event</th>
            <th className="log-table__cell">Stack</th>
            <th className="log-table__cell">Content</th>
            <th className="log-table__cell">User Email</th>
            <th className="log-table__cell">Date</th>
            <th className="log-table__cell" />
          </tr>
        </thead>
        <tbody>
          {[...collection].reverse().map((item) => {
            return (
              <tr className="log-table__row" key={item._id}>
                <td className="log-table__cell">{item.event}</td>
                <td className="log-table__cell">{item.stack}</td>
                <td className="log-table__cell">{item.content}</td>
                <td className="log-table__cell log-table__cell--email">
                  {item.userEmail}
                </td>
                <td className="log-table__cell log-table__cell--date">
                  {formatDate(item.createdAt)}
                </td>
                <td className="log-table__cell">
                  <Button
                    size="sm"
                    variant="link"
                    className="log-table__open-session-link"
                    onClick={() => {
                      if (onShowLogDetailed) {
                        onShowLogDetailed(item);
                      }
                    }}>
                    <InfoSquare size={18} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  },
);

export default MessagingApiLogTable;
