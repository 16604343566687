export const LogType = {
  Info: 'info',
  Error: 'error',
  Warning: 'warning',
};

export const LogSource = {
  Demo: 'https://mploy-api.demo.p2h-cd.com/log',
  Staging: 'https://mploy-api.p2h-cd.com/log',
  Preprod: 'https://api.preprod.seekrjobs.com/log',
  Prod: 'https://api.seekrjobs.com/log',
  Mobile: 'https://api-logging.seekrjobs.com/mobile-log',
};

export const ApiType = {
  Main: 'main',
  Messaging: 'messaging',
};
