import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { ArrowRepeat, Search } from 'react-bootstrap-icons';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import defer from 'lodash/defer';
import throttle from 'lodash/throttle';

import { fetchLogs } from 'tools/api';
import LogTable from 'components/mobile/LogTable';
import AppSessionContent from 'components/mobile/AppSessionContent';
import { LogType, LogSource } from 'config';

const PAGE_SIZE = 30;
const SCROLL_THRESHOLD = 100;

function MobileLogsPage() {
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState([]);
  const [dateTo, setDateTo] = useState('');
  const [totalCount, setTotalCount] = useState(+Infinity);
  const [environment, setEnvironment] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [type, setType] = useState('');
  const [skip, setSkip] = useState(0);
  const [sessionId, setSessionId] = useState('');
  const html = useRef(window.document.documentElement);
  const headerRef = useRef();
  const logTableRef = useRef();

  const filterParams = useMemo(() => {
    return omitBy(
      {
        type,
        environment,
        userEmail,
      },
      isEmpty,
    );
  }, [environment, userEmail, type]);

  const fetchInitial = useCallback(() => {
    const params = { limit: PAGE_SIZE, skip: 0, ...filterParams };

    setLoading(true);

    fetchLogs(params, LogSource.Mobile).then((result) => {
      setTotalCount(result.totalCount);
      setCollection(result.collection);
      setDateTo(result.collection[0]?.createdAt);
      setSkip(PAGE_SIZE);
      setLoading(false);

      defer(() => {
        html.current.scrollTop = logTableRef.current?.offsetHeight;
      });
    });
  }, [filterParams]);

  const fetchMore = useCallback(() => {
    if (loading) {
      return;
    }

    const params = { limit: PAGE_SIZE, skip, dateTo, ...filterParams };

    setLoading(true);

    fetchLogs(params, LogSource.Mobile).then((result) => {
      const scrollTop = html.current?.scrollTop;
      const tableHeight = logTableRef.current?.offsetHeight;

      setTotalCount(result.totalCount);
      setCollection(collection.concat(result.collection));
      setSkip(skip + PAGE_SIZE);
      setLoading(false);

      defer(() => {
        const diff = logTableRef.current?.offsetHeight - tableHeight;

        html.current.scrollTop = scrollTop + diff;
      });
    });
  }, [filterParams, skip, dateTo, collection, loading]);

  useEffect(() => {
    fetchInitial();
  }, [fetchInitial]);

  useEffect(() => {
    const onScroll = throttle((e) => {
      if (!loading && e.target.documentElement.scrollTop <= SCROLL_THRESHOLD) {
        fetchMore();
      }
    }, 150);

    window.document.addEventListener('scroll', onScroll);

    return () => {
      window.document.removeEventListener('scroll', onScroll);
    };
  }, [fetchMore, loading]);

  return (
    <div className="page">
      <Navbar fixed="top" className="header-filters" ref={headerRef}>
        <Form.Group className="header-filters__form-group">
          <Form.Label className="header-filters__form-label">
            Environment
          </Form.Label>
          <Form.Select onChange={(e) => setEnvironment(e.target.value)}>
            <option value="">All</option>
            <option value="demo">Demo</option>
            <option value="staging">Staging</option>
            <option value="preprod">Preprod</option>
            <option value="prod">Prod</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="header-filters__form-group">
          <Form.Label className="header-filters__form-label">
            User Email
          </Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              value={emailValue}
              className="header-filters__email-input"
              onChange={(e) => setEmailValue(e.target.value)}
            />
            <CloseButton
              type={'button'}
              className="header-filters__email-clear"
              disabled={emailValue === ''}
              onClick={() => {
                setUserEmail('');
                setEmailValue('');
              }}
            />
            <Button
              type={'button'}
              disabled={emailValue === userEmail}
              onClick={() => {
                setUserEmail(emailValue);
              }}>
              <Search size={18} />
            </Button>
          </InputGroup>
        </Form.Group>
        <Form.Check
          type="switch"
          label="Only Errors"
          onChange={(e) => setType(e.target.checked ? LogType.Error : '')}
        />
        <Button
          type={'button'}
          className="header-filters__refresh-button"
          onClick={() => {
            fetchInitial();
          }}>
          <ArrowRepeat size={23} />
        </Button>
      </Navbar>

      {totalCount === 0 ? (
        <div className="empty-result">No Results</div>
      ) : (
        <LogTable
          stickyOffset={
            headerRef.current?.offsetHeight + headerRef.current?.offsetTop
          }
          hiddenFields={environment === '' ? [] : ['environment']}
          collection={collection}
          ref={logTableRef}
          onSessionOpen={(id) => setSessionId(id)}
        />
      )}
      <Modal
        show={sessionId}
        dialogClassName="session-modal-dialog"
        onShow={() => {
          html.current?.classList.add('modal-open');
        }}
        onHide={() => {
          setSessionId('');
        }}
        onExited={() => {
          html.current?.classList.remove('modal-open');
        }}>
        {sessionId ? <AppSessionContent sessionId={sessionId} /> : null}
      </Modal>
    </div>
  );
}

export default MobileLogsPage;
