import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ArrowRepeat } from 'react-bootstrap-icons';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import defer from 'lodash/defer';
import throttle from 'lodash/throttle';

import { fetchLogs } from 'tools/api';
import { ApiType, LogSource } from 'config';
import MainApiLogTable from 'components/server/MainApiLogTable';
import LogDetailModal from 'components/server/LogDetailModal';
import formatDate from 'tools/formatDate';

const PAGE_SIZE = 30;
const SCROLL_THRESHOLD = 100;

function ServerMainApiLogsPage() {
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState([]);
  const [dateTo, setDateTo] = useState('');
  const [totalCount, setTotalCount] = useState(+Infinity);
  const [skip, setSkip] = useState(0);
  const html = useRef(window.document.documentElement);
  const headerRef = useRef();
  const logTableRef = useRef();
  const [logSource, setLogSource] = useState(LogSource.Demo);
  const [logDetailed, setLogDetailed] = useState(null);

  const filterParams = useMemo(() => {
    return omitBy(
      {
        api: ApiType.Main,
      },
      isEmpty,
    );
  }, []);

  const fetchInitial = useCallback(() => {
    const params = { limit: PAGE_SIZE, skip: 0, ...filterParams };

    setLoading(true);

    fetchLogs(params, logSource).then((result) => {
      setTotalCount(result.totalCount);
      setCollection(result.collection);
      setDateTo(result.collection[0]?.createdAt);
      setSkip(PAGE_SIZE);
      setLoading(false);

      defer(() => {
        html.current.scrollTop = logTableRef.current?.offsetHeight;
      });
    });
  }, [filterParams, logSource]);

  const fetchMore = useCallback(() => {
    if (loading) {
      return;
    }

    const params = { limit: PAGE_SIZE, skip, dateTo, ...filterParams };

    setLoading(true);

    fetchLogs(params, logSource).then((result) => {
      const scrollTop = html.current?.scrollTop;
      const tableHeight = logTableRef.current?.offsetHeight;

      setTotalCount(result.totalCount);
      setCollection(collection.concat(result.collection));
      setSkip(skip + PAGE_SIZE);
      setLoading(false);

      defer(() => {
        const diff = logTableRef.current?.offsetHeight - tableHeight;

        html.current.scrollTop = scrollTop + diff;
      });
    });
  }, [filterParams, skip, dateTo, collection, loading, logSource]);

  useEffect(() => {
    fetchInitial();
  }, [fetchInitial]);

  useEffect(() => {
    const onScroll = throttle((e) => {
      if (!loading && e.target.documentElement.scrollTop <= SCROLL_THRESHOLD) {
        fetchMore();
      }
    }, 150);

    window.document.addEventListener('scroll', onScroll);

    return () => {
      window.document.removeEventListener('scroll', onScroll);
    };
  }, [fetchMore, loading]);

  return (
    <div className="page">
      <Navbar fixed="top" className="header-filters" ref={headerRef}>
        <Form.Group className="header-filters__form-group">
          <Form.Label className="header-filters__form-label">
            Environment
          </Form.Label>
          <Form.Select onChange={(e) => setLogSource(e.target.value)}>
            <option value={LogSource.Demo}>Demo</option>
            <option value={LogSource.Staging}>Staging</option>
            <option value={LogSource.Preprod}>Preprod</option>
            <option value={LogSource.Prod}>Prod</option>
          </Form.Select>
        </Form.Group>
        <Button
          type={'button'}
          className="header-filters__refresh-button"
          onClick={() => {
            fetchInitial();
          }}>
          <ArrowRepeat size={23} />
        </Button>
      </Navbar>
      {totalCount === 0 ? (
        <div className="empty-result">No Results</div>
      ) : (
        <MainApiLogTable
          stickyOffset={
            headerRef.current?.offsetHeight + headerRef.current?.offsetTop
          }
          collection={collection}
          onShowLogDetailed={setLogDetailed}
          ref={logTableRef}
        />
      )}
      <Modal
        show={logDetailed?._id}
        dialogClassName="session-modal-dialog"
        onShow={() => {
          html.current?.classList.add('modal-open');
        }}
        onHide={() => {
          setLogDetailed(null);
        }}
        onExited={() => {
          html.current?.classList.remove('modal-open');
        }}>
        <Modal.Header closeButton>
          Date: {formatDate(logDetailed?.createdAt)}
        </Modal.Header>
        <Modal.Body>
          {logDetailed?._id ? (
            <LogDetailModal logDetailed={logDetailed} />
          ) : null}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ServerMainApiLogsPage;
