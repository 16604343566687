export async function fetchLogs(params, url) {
  const queryString = params ? '?' + new URLSearchParams(params) : '';

  const result = await fetch(url + queryString, {
    cache: 'no-cache',
  });

  if (result.ok) {
    return {
      collection: await result.json(),
      totalCount: Number(result.headers.get('x-total-count')),
    };
  }

  throw new Error('API request error');
}
